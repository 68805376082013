@use "../config/" as *;

.waf-form {
    .form-head {
        @extend %gap-3;
        @extend %flex-column;
        @extend %mb-10;
        .form-title {
            @extend %capitalize;
            @extend %neutral-800;
            @extend %font-24-pb;
        }
        .form-sub-title {
            line-height: 2rem;
            @extend %font-14-pr;
            @extend %neutral-800;
        }
    }
    .form-data {
        @extend %pure-white-900-bg;
        @extend %p-10-4;
    }
    .form-banner {
        height: 28.5rem;
        @extend %primary-300-bg;
        img {
            @extend %h-100;
        }
    }
}
.custom-form {
    .form-element {
        @extend %relative;
        @extend %flex-column;
    }
    .form-label {
        @extend %mb-2;
        @extend %capitalize;
        @extend %font-14-pm;
        @extend %neutral-800;
    }
    .form-group {
        &:not(:last-child) {
            @extend %mb-7;
        }
        &-address {
            .form-input {
                min-height: 16rem;
                resize: none;
                line-height: 2rem;
                text-transform: none;
                @extend %p-4;
            }
        }
    }
    .form-group-wrapper {
        @extend %mb-7;
    }
    .form-input {
        border: .1rem solid var(--neutral-1000);
        height: 4.8rem;
        @extend %w-100;
        @extend %capitalize;
        @extend %neutral-800;
        @extend %half-radius;
        @extend %p-2-4;
        @extend %font-14-pr;
        &::placeholder {
            @extend %neutral-500;
        }
    }
    .form-input-drop {
        border: .1rem solid var(--neutral-1000);
        height: 4.8rem;
        @extend %w-100;
        @extend %flex-sb-c;
        @extend %half-radius;
        @extend %p-2-4;
        &::after {
            transition: .3s all ease;
            transform: rotate(90deg);
            @extend %neutral-500;
            @include icon(p-arrow-right);
        }
        .btn-text {
            @extend %capitalize;
            @extend %neutral-500;
            @extend %font-14-pr;
        }
        &[aria-expanded="true"] {
            & ~ .form-dropdown {
                @include dropdown(open);
            }
            &::after {
                transform: rotate(270deg);
            }
        }
    }
    .form-dropdown {
        border: .1rem solid var(--neutral-1000);
        border-radius: 0 0 var(--half-radius) var(--half-radius);
        max-height: 16rem;
        overflow-y: auto;
        z-index: var(--z-dropdown);
        @extend %pure-white-900-bg;
        @extend %w-100;
        @include position(7rem,null,null,0);
        @include dropdown(close);
        .sub-ul {
            list-style: none;
            @extend %pl-0;
        }
        .btn-item {
            @extend %text-left;
            @extend %p-2-4;
            @extend %w-100;
            .btn-text {
                @extend %font-14-pr;
                @extend %capitalize;
                @extend %neutral-800;
            }
        }
    }
    .error-msg {
        @extend %error-900;
        @extend %font-12-pm;
    }
    .btn-outline {
        height: 4.8rem;
        border: .1rem solid var(--primary-300);
        @extend %half-radius;
        @extend %p-2-4;
        @extend %w-100;
        @extend %primary-300-bg;
        .btn-text {
            @extend %gap-2;
            @extend %flex-c-c;
            @extend %uppercase;
            @extend %font-14-pb;
            @extend %secondary-800;
            &:before {
                width: 1.4rem;
                height: .1rem;
                @extend %secondary-800-bg;
            }
            &::after {
                @extend %secondary-800;
                @include icon(drop-arrow);
            }
        }
    }
    .disclaimer {
        @extend %mb-5;
    }
    .acknowledge {
        @extend %mb-7;
    }
    .checkbox-container {
        @extend %gap-2;
        @extend %flex-n-fs;
        .text {
            line-height: 1.8rem;
            @extend %neutral-800;
            @extend %font-12-pr;
            &-bold {
                font-weight: 700;
            }
            .link-text {
                font-weight: 700;
                @extend %primary-700;
            }
        }
        input[type=checkbox] {
            @extend %d-none;
            &:checked ~ .checkmark {
                border: 0;
                @extend %primary-300-bg;
                &::after {
                    @include icon(checkmark, 10);
                    @extend %pure-white-900;
                }
            }
        }
        .checkmark {
            width: 1.3rem;
            height: 1.3rem;
            flex-shrink: 0;
            border-radius: .3rem;
            border: .1rem solid clr(neutral-800);
            margin-top: .3rem;
            cursor: pointer;
            @extend %flex-c-c;
        }
    }
    button[type="button"]:disabled,.btn-disabled {
        opacity: .5;
        border: .1rem solid var(--secondary-800);
        background: transparent;
        .btn-text {
            &:before {
                content: '';
            }
            &::after {
                content: unset;
            }
        }
    }
    .varified {
        .form-element {
            &::after {
                width: 2rem;
                height: 2rem;
                @extend %circle-radius;
                @extend %success-900-bg-3;
                @extend %success-800;
                @extend %flex-c-c;
                @include position(null,var(--space-4),1.4rem);
                @include icon(check-drop,10);
            }
        }
        .form-label {
            @extend %success-800;
        }
        .form-input {
            border: .1rem solid var(--success-800);
        }
        &.mobile-number {
            .selected-title {
                border: .1rem solid var(--success-800);
            }
        }
    }
    .email-verify,.mobile-verify {
        .form-input {
            padding-right: 7.8rem;
        }
        .verify-text {
            @extend %neutral-600;
            @extend %font-12-pr;
        }
        .btn-varify {
            width: max-content;
            height: 2.3rem;
            @extend %px-2;
            @extend %primary-300-bg;
            @extend %half-radius;
            @include position(null,var(--space-4),var(--space-3),null);
            .btn-text {
                @extend %secondary-800;
                @extend %uppercase;
                @extend %font-10-pb;
            }
        }
    }
    .locked {
        .form-input,.selected-title {
            @extend %neutral-50-bg;
        }
        .form-element {
            &::after {
                width: 2rem;
                height: 2rem;
                content: unset;
                @extend %neutral-600;
                @extend %flex-c-c;
                @include position(null,var(--space-4),1.4rem);
            }
        }
    }
    .mobile-number {
        .mobile-number-wrapper {
            @extend %gap-2;
            @extend %flex;
        }
        .selected-title {
            border: .1rem solid var(--neutral-1000);
            height: 4.8rem;
            @extend %w-100;
            @extend %gap-2;
            @extend %flex-c-c;
            @extend %half-radius;
            @extend %p-2-4;
            .btn-text {
                @extend %capitalize;
                @extend %neutral-500;
                @extend %font-14-pr;
            }
            &::after {
                transition: .3s all ease;
                transform: rotate(90deg);
                @extend %neutral-500;
                @include icon(p-arrow-right);
            }
            &[aria-expanded="true"] {
                &::after {
                    transform: rotate(270deg);
                }
                & ~ .select-list {
                    @include dropdown(open);
                }
            }
        }
        .select-list {
            border: .1rem solid var(--neutral-1000);
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            max-height: 16rem;
            overflow-y: auto;
            z-index: var(--z-dropdown);
            @extend %pure-white-900-bg;
            @include position(7rem,null,null,0);
            @include dropdown(close);
            @extend %flex-column;
            .list-item {
                @extend %gap-2;
                @extend %p-2-4;
                @extend %flex-n-c;
            }
            .btn-text {
                @extend %capitalize;
                @extend %neutral-800;
                @extend %font-14-pr;
            }
        }
        .country-flag {
            width: 2.5rem;
            height: 1.5rem;
        }
    }
    input[type="date"] {
        text-transform: uppercase;
        color: var(--neutral-500);
        -webkit-min-logical-width: calc(100% - 1.5rem);
        background: var(--pure-white-900);
    }
    @supports (-webkit-touch-callout: none) { 
        input[type="date"] {
            @include background(var(--pure-white-900), "svg/calendar.svg", center right 1.3rem / 2.5rem 2.5rem no-repeat);
        }
    }
    .form-search {
        position: sticky;
        top: 0;
    }
}
@include mq(col-lg) {
    .waf-form {
        .form-head {
            margin-bottom: var(--space-12);
            .form-title {
                font-size: 3.6rem;
                line-height: 4rem;
            }
            .form-sub-title {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
        .login-wrapper {
            display: flex;
        }
        .form-banner {
            width: 42%;
            height: unset;
            max-height: calc(var(--window-inner-height) - var(--header-height));
            position: sticky;
            position: -webkit-sticky;
            top: var(--header-height);
        }
        .form-data {
            width: 58%;
            @include flex-config(flex,null,center);
        }
    }
    .custom-form {
        display: flex;
        flex-wrap: wrap;
        column-gap: var(--space-4);
        .form-group {
            width: calc(50% - var(--space-2));
            &-address,&-default-address {
                width: 100%;
            }
        }
        button[type="button"]:disabled,.btn-disabled {
            &:hover {
                .btn-text {
                    &::before {
                        width: 1.4rem;
                        overflow: visible;
                    }
                }
            }
        }
        .varified {
            .form-input {
                padding-right: var(--space-10);
            }
        }
    }
}
@use "./config/" as *;
@forward "./main-scss/";
@forward "./partials/form-common";
@each $icon,
$value in $user-setting {
    .#{$icon} {
        &::before {
            content: $value;
        }
    }
}
.waf-user-profile {
    @extend %p-4;
    .form-label {
        opacity: .7;
    }
    .waf-head {
        @extend %mb-8;
        @extend %flex-sb-c;
        .title {
            @extend %uppercase;
            @extend %secondary-800;
            @extend %font-20-pb;
        }
    }
    .waf-body {
        @extend %half-radius;
        @extend %p-4;
        @extend %pure-white-900-bg;
    }
    .setting {
        &-list {
            width: 20rem;
            z-index: var(--z-user-profile-setting);
            list-style: none;
            border: .1rem solid var(--neutral-50);
            @include dropdown(close);
            @include position(4.5rem, 0, null, null);
            @extend %gap-2;
            @extend %py-3;
            @extend %pure-white-900-bg;
            @extend %half-radius;
        }
        &-item {
            height: 3rem;
        }
        &-text {
            @extend %neutral-800;
            @extend %uppercase;
            @extend %font-14-pm;
            &:before {
                font-family: $font-icon;
                @extend %pr-2;
                @extend %neutral-800;
            }
        }
        &-link {
            @extend %flex-n-c;
            @extend %px-3;
            @extend %w-100;
            @extend %h-100;
        }
        &-profile {
            @extend %relative;
            .btn-setting {
                height: 3.8rem;
                @extend %flex-n-c;
                @extend %px-4;
                @extend %half-radius;
                @extend %primary-300-bg;
                .btn-text {
                    @extend %secondary-800;
                    @extend %font-12-pb;
                    @extend %uppercase;
                    @extend %font-0;
                }
                &::before {
                    @extend %secondary-800;
                    @include icon(setting, 14);
                }
                &[aria-expanded="true"] {
                    & ~ .setting-list {
                        @include dropdown(open);
                    }
                }
            }
        }
    }
    .btn-group {
        @extend %d-none;
        @extend %gap-4;
        .btn {
            flex: 1;
            &-cancel {
                height: 4.8rem;
                border: .1rem solid var(--secondary-800);
                background: transparent;
                transition: .3s;
                @extend %half-radius;
                @extend %p-2-4;
                @extend %w-100;
                .btn-text {
                    @extend %gap-2;
                    @extend %flex-c-c;
                    @extend %font-14-pb;
                    @extend %uppercase;
                    @extend %secondary-800;
                    &:before {
                        content: '';
                        width: 1.4rem;
                        height: .1rem;
                        transition: .3s;
                        @extend %secondary-800-bg;
                    }
                    &::after {
                        width: 1.4rem;
                        transition: .3s;
                        content: unset;
                    }
                }
                &:hover {
                    border: .1rem solid var(--primary-800);
                    @extend %primary-300-bg;
                    .btn-text {
                        &:before {
                            width: 0;
                            overflow: hidden;
                        }
                        &::after {
                            @include icon(drop-arrow);
                        }
                    }
                }
            }
            &-outline {
                border: .1rem solid var(--primary-300);
                background: var(--primary-300);
                .btn-text {
                    &::before {
                        content: unset;
                    }
                    &::after {
                        @include icon(drop-arrow);
                    }
                }
                &:hover {
                    .btn-text {
                        &::after {
                            @include icon(drop-arrow);
                        }
                    }
                }
            }
        }
    }
    .locked {
        .form-element {
            &::after {
                @include icon(lock, 14);
            }
        }
    }
    &.user-profile-edit {
        .form-label {
            opacity: 1;
        }
        .btn-group {
            @extend %flex;
        }
        // .locked {
        //     .form-element {
        //         &::after {
        //             @include icon(lock,14);
        //         }
        //     }
        // }
    }
    .custom-form {
        button[type="button"]:disabled,
        .btn-disabled,
        input:disabled {
            opacity: 1;
            border: .1rem solid var(--neutral-1000);
            background: var(--neutral-50);
            &::after {
                content: unset;
            }
        }
    }
    .email-otp {
        .otp-title {
            @extend %mb-3;
            @extend %font-14-pm;
            @extend %neutral-800;
        }
        .otp-sub-title {
            line-height: 2rem;
            @extend %font-14-pr;
            @extend %neutral-500;
        }
        .otp-wrap {
            @extend %mb-4;
            @extend %gap-4;
            @extend %flex;
            .form-input {
                flex-basis: 4.3rem;
                padding: 0;
                @extend %text-center;
            }
        }
        .resend-otp {
            justify-content: end;
            @extend %gap-3;
            @extend %flex-column;
            @extend %text-right;
            .text {
                @extend %secondary-800;
                @extend %font-14-pr;
                .otp {
                    font-weight: 700;
                    @extend %secondary-800;
                }
            }
        }
        .otp-wrap {
            + .error-msg {
                display: block;
                margin-top: var(--space-3-neg);
                padding-bottom: var(--space-2);
                @extend %text-right;
            }
        }
        .otp-failed {
            .form-input {
                border: .1rem solid var(--error-800);
                color: var(--error-800);
            }
        }
        .otp-success {
            .form-input {
                border: .1rem solid var(--success-800);
                color: var(--success-800);
            }
        }
    }
    .email-address {
        .form-input {
            text-transform: none;
        }
    }
    .btn-edit {
        margin-inline: auto var(--space-3);
        height: 3.8rem;
        width: auto;
        border: .1rem solid var(--secondary-800);
        @extend %px-3;
        @extend %half-radius;
        @extend %flex-c-c;
        .edit-profile {
            @extend %secondary-800;
            @extend %font-12-pb;
        }
    }
}
@include mq(col-md) {
    .waf-user-profile {
        .layout-wrapper {
            max-width: 100%;
        }
    }
}
@include mq(col-lg) {
    .waf-user-profile {
        padding: var(--space-5) 0;
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .custom-form {
            .btn-group {
                width: 100%;
            }
        }
        .email-otp {
            width: 100%;
            display: flex;
            gap: var(--space-4);
            .otp-wrap {
                gap: var(--space-3);
                .form-element {
                    flex: 1;
                    height: 4.8rem;
                }
                .form-input {
                    padding: 0;
                    height: 100%;
                }
            }
            .form-group {
                margin-bottom: 0;
            }
        }
        .otp-wrap {
            margin-left: auto;
            width: 50%;
        }
        .setting-profile {
            .btn-setting {
                gap: var(--space-2);
                .btn-text {
                    font-size: 1.2rem;
                }
            }
        }
        .btn-edit {
            .edit-profile {
                &:before {
                    padding-right: var(--space-2);
                    font-size: 1.4rem;
                }
            }
        }
    }
}